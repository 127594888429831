import Vue from 'vue'
import Vuex from 'vuex'
import DashCore from 'dash-js'
import ScopeCore from 'scope-js'
import ActivitiesCore from 'activities-js'
import UtilitiesCore from 'utilities-js'
import CompanyCore from 'company-js'
import { ProfileCore } from 'profile-ts'
import IDCore from 'id-js'
import i18n from '@/i18n'
// import Cookies from 'js-cookie'

import account from './modules/account'
import order from './modules/order'
import charge from './modules/charge'
import plan from './modules/plan'
import invoice from './modules/invoice'
import subscription from './modules/subscription'
import customer from './modules/customer'
import trf from './modules/trf'
import log from './modules/log'
import webhook from './modules/webhook'
import commons from './modules/commons'
import user from './modules/user'
import invite from './modules/invite'
import role from './modules/role'
import merchant from './modules/merchant'
import profile from './modules/profile'
import nps from './modules/nps'
import shop from './modules/shop'
import home from './modules/home'
import recipients from './modules/recipients'
import paymentLinks from './modules/payment-links'
import physicalWorld from './modules/physical-world'
import affiliationsSettings from './modules/affiliations-settings.store'
import kyc from './modules/kyc'
import chargeback from './modules/chargeback'
import app from './modules/app.store'

/* ENUMS AND OTHERS */
import packageJson from '@/../package.json'
import { TERMINAL_ROUTES_ENUM } from '../enums/physical-world.enum'
import { isSellerOrSalesUserRole } from '@/enums/user-role.enum'
import { EXTERNAL_ENDPOINTS } from '@/external-endpoints'

const { version } = packageJson

Vue.use(Vuex)

const coreParams = {
  environment: process.env.VUE_APP_CORE_ENV,
  theme: process.env.VUE_APP_THEME
}

export const DashInstance = new DashCore(coreParams)
export const ProfileInstance = new ProfileCore(coreParams)
export const ScopeInstance = new ScopeCore(coreParams)
export const ActivitiesInstance = new ActivitiesCore(coreParams)
export const UtilitiesInstance = new UtilitiesCore(coreParams)
export const CompanyInstance = new CompanyCore(coreParams)
export const IDCoreInstance = new IDCore(coreParams)

export const catchError = function (error, context) {
  if (error.status === 401) {
    context.dispatch('DELETE_AUTHORIZATION')
  } else if (error.status === 402) {
    context.dispatch('SET_MERCHANT_DELINQUENT', true)
  } else if (error.status === 412) {
    if (!error.body) error.body = {}
    const messages = [{ message: i18n.t('alert.password-error') }]
    error.body.errors
      ? error.body.errors.push(messages)
      : (error.body.errors = messages)
  } else if (error.status === 423) {
    if (!error.body) error.body = {}
    error.body.hasLocked = true
  } else if (error.status === 400 && error.body.notifications) {
    const notifications = Object.values(error.body.notifications)
    const messages = notifications.map(message => {
      return { message: message[0] }
    })
    error.body.errors
      ? error.body.errors.push(messages)
      : (error.body.errors = messages)
  } else if (error.status !== 400 && error.status !== 422) {
    context.commit('toggleGlobalError', { status: true, code: error.status })
  }
  if (window.newrelic) {
    window.newrelic.noticeError(error.message)
  }
  return Promise.reject(error)
}

export const env = ProfileInstance.envAcronym

const store = new Vuex.Store({
  strict: !['production'].includes(process.env.NODE_ENV),
  modules: {
    account,
    order,
    charge,
    plan,
    invoice,
    subscription,
    customer,
    trf,
    log,
    webhook,
    commons,
    user,
    invite,
    role,
    merchant,
    profile,
    nps,
    shop,
    home,
    recipients,
    ...physicalWorld,
    paymentLinks,
    kyc,
    chargeback,
    affiliationsSettings,
    app
  },
  state: {
    globalLoading: true,
    localLoading: false,
    globalError: false,
    maintenance: false,
    globalErrorCode: '',
    appVersion: version
  },
  mutations: {
    toggleGlobalLoading (state, status) {
      state.globalLoading = status
    },
    toggleGlobalError (state, { status, code }) {
      if (
        code === 404 &&
        status &&
        isSellerOrSalesUserRole(store.getters.userRole.key)
      ) {
        // eslint-disable-next-line no-console
        console.error('[toggleGlobalError] && isSellerUserRole')

        window.location.assign(
          EXTERNAL_ENDPOINTS.SHOP.url(
            store.getters.merchantId,
            store.getters.accountId
          )
        )
        return
      }

      state.globalError = status
      state.globalErrorCode = code
    },
    toggleLocalLoading (state, status) {
      state.localLoading = status
    }
  },
  getters: {
    categoryRoutes () {
      return [
        {
          category: 'financial',
          children: ['balance', 'transfers']
        },
        {
          category: 'sales',
          children: ['orders', 'charges', 'trf']
        },
        {
          category: 'recurrence',
          children: ['plans', 'invoices', 'subscriptions']
        },
        {
          category: 'team',
          children: ['users', 'invites', 'invites-create', 'logs']
        },
        {
          category: 'settings',
          children: [
            'account-config',
            'gateways',
            'fees',
            'payment-methods',
            'functionalities',
            'recurrence',
            'order-config',
            'webhook-config',
            'webhook-config-form',
            'keys'
          ]
        },
        {
          category: 'physical-world',
          children: [
            'service-orders',
            'request',
            'roll-request',
            TERMINAL_ROUTES_ENUM.TERMINALS,
            TERMINAL_ROUTES_ENUM.TERMINAL_REQUEST
          ]
        }
      ]
    },
    appVersion (state) {
      return state.appVersion
    },
    maintenance (state) {
      return state.maintenance
    },
    localLoading (state) {
      return state.localLoading
    },
    globalError (state) {
      return state.globalError
    },
    globalErrorCode (state) {
      return state.globalErrorCode
    },
    globalLoading (state) {
      return state.globalLoading
    }
  },
  actions: {
    toggleGlobalLoading ({ commit }, status) {
      commit('toggleGlobalLoading', status)
    },
    toggleLocalLoading ({ commit }, status) {
      commit('toggleLocalLoading', status)
    },
    clearStorage () {
      sessionStorage.removeItem(`${env}mp_dash`)
    },
    goToHome () {
      window.location.assign(process.env.VUE_APP_PUBLIC_PATH)
    },
    catchError (context, error) {
      catchError(error, context)
    }
  }
})

export default store
