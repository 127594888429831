import DashCore from 'dash-js'
import Cookies from 'js-cookie'
import { ProfileInstance, IDCoreInstance, catchError } from '@/store'
import { setCookieDash, updateCookieDash } from '@/support'

import {
  getCookieTimelineByAccountId,
  setCookieTimelineWithAccountId
} from '@/support/cookieTimeline'

import {
  identify as identifyAppcues,
  setAccountInAppcues
} from '@/vendor/appcues.vendor'
import { isSellerUserRole } from '@/enums/user-role.enum'

import {
  isValidStepTimelineActivation,
  TIMELINE_STEPS_ENUM as STEPS_TIMELINE
} from '@/components/project/globals/DashTimelineActivation/timeline-activation-steps.enum'

export const DashInstance = new DashCore({
  environment: process.env.VUE_APP_CORE_ENV,
  theme: process.env.VUE_APP_THEME
})

function getCookieOnboardingDash (context, cookieDash) {
  context.commit('toggleGlobalLoading', false)
  if (!Object.keys(cookieDash.onboarding).includes('show_pix')) {
    cookieDash.onboarding.show_pix = true
  }
  context.commit(
    'SET_SHOW_ONBOARDING_HELP',
    cookieDash.onboarding.show_greeting
  )

  const onboardingObj = {
    ...cookieDash.onboarding,
    show_modal_header: Object.keys(cookieDash.onboarding).includes(
      'show_modal_header'
    )
      ? cookieDash.onboarding.show_modal_header
      : !cookieDash.onboarding.show_greeting,
    is_new_user: Object.keys(cookieDash.onboarding).includes('is_new_user')
      ? cookieDash.onboarding.is_new_user
      : cookieDash.onboarding.show_greeting
  }

  context.commit('SET_ONBOARDING', onboardingObj)
}

function fetchOnboardingDash (context) {
  return ProfileInstance.onboarding
    .get('dash')
    .then(res => {
      context.commit('toggleGlobalLoading', false)
      if (res && Object.entries(res.data).length) {
        if (!Object.keys(res.data).includes('show_pix'))
          res.data.show_pix = true
        context.commit('SET_SHOW_ONBOARDING_HELP', res.data.show_greeting)

        const onboardingObj = {
          ...res.data,
          show_modal_header: Object.keys(res.data).includes('show_modal_header')
            ? res.data.show_modal_header
            : !res.data.show_greeting,
          is_new_user: Object.keys(res.data).includes('is_new_user')
            ? res.data.is_new_user
            : res.data.show_greeting
        }

        context.commit('SET_ONBOARDING', onboardingObj)
      } else {
        context.commit('SET_SHOW_ONBOARDING_HELP', true)
        context.commit('SET_ONBOARDING', {
          show_greeting: true,
          show_pix: true,
          is_new_user: true
        })
      }

      setCookieDash({ onboarding: { ...res.data } })

      context.commit('toggleGlobalLoading', false)
      return Promise.resolve(res.data)
    })
    .catch(err => {
      context.commit('toggleGlobalLoading', false)
      if (!err.status || err.status === 500) {
        return ''
      } else {
        return catchError(err, context)
      }
    })
}

const profile = {
  state: {
    profile: undefined,
    me: undefined,
    onboarding: {
      is_new_user: false,
      show_greeting: false,
      step: 0,
      sent_feedback: false,
      show_pix: false,
      show_modal_header: false,
      closed_tooltip_filter: true,
      closed_tooltip_key: true,
      closed_tooltip_value: true,
      closed_tooltip_report: true,
      summary: {
        closed_modal: true,
        closed_tooltip_filter_date: true
      }
    },
    showOnboarding: false,
    showOnboardingHelp: true,
    timelineActivation: {
      step: STEPS_TIMELINE.NONE
    }
  },
  mutations: {
    SET_PROFILE (state, profile) {
      state.profile = profile
    },
    SET_ME (state, me) {
      state.me = me
    },
    SET_ONBOARDING (state, onboarding) {
      state.onboarding = Object.assign(state.onboarding, onboarding)
    },
    SET_SHOW_GREETING (state, showGreeting) {
      state.onboarding.show_greeting = showGreeting
    },
    SET_SHOW_ONBOARDING (state, showOnboarding) {
      state.showOnboarding = showOnboarding
    },
    SET_ONBOARDING_STEP (state, onboardingStep) {
      state.onboarding.step = onboardingStep
    },
    SET_PIX_ONBOARDING (state, value) {
      state.onboarding.show_pix = value
    },
    SET_SHOW_MODAL_HEADER (state, value) {
      state.onboarding.show_modal_header = value
    },
    SET_TOOLTIP_FILTER_ONBOARDING (state, { type, value }) {
      state.onboarding[`closed_tooltip_${type}`] = value
    },
    SET_SHOW_ONBOARDING_HELP (state, showOnboardingHelp) {
      state.showOnboardingHelp = showOnboardingHelp
    },
    SET_ONBOARDING_SUMMARY (state, onboardingSummaryData) {
      state.onboarding.summary.closed_modal = onboardingSummaryData.closed_modal
      state.onboarding.summary.closed_tooltip_filter_date =
        onboardingSummaryData.closed_tooltip_filter_date
    },
    IDENTIFY_APPCUES (state) {
      const { user, merchant } = state.profile
      const userVars = {
        userId: user.id,
        userEmail: user.email,
        userName: `${user.first_name} ${user.last_name}`,
        merchantId: merchant ? merchant.id : null
      }
      identifyAppcues(userVars)
    },
    SET_ACCOUNT_VENDOR (state) {
      const { merchant, user } = state.profile
      const { account } = merchant
      const userVars = {
        userId: user.id,
        merchanId: merchant.id,
        accountId: account.id,
        accountName: account.name,
        businessModel: account.business_model,
        accountType: account.account_type.value
      }
      setAccountInAppcues(userVars)
    },
    SET_TIMELINE_STEP_ACTIVATION (state, step) {
      if (isValidStepTimelineActivation(step)) {
        state.timelineActivation.step = step
      }
    }
  },
  getters: {
    profile (state) {
      return state.profile
    },
    me (state) {
      return state.me
    },
    isEmployee (state) {
      return state?.profile?.user?.is_employee
    },
    onboarding (state) {
      return state.onboarding
    },
    onboardingStep (state) {
      return state.onboarding.step
    },
    showModalHeaderOnboarding (state) {
      return state.onboarding.show_modal_header
    },
    isOnboardingSummaryClosed (state) {
      return state.onboarding.summary.closed_modal
    },
    showOnboarding (state) {
      return state.showOnboarding
    },
    showOnboardingHelp (state) {
      return state.showOnboardingHelp
    },
    merchant (state) {
      if (state.profile) {
        return {
          id: state.profile.merchant.id,
          type: state.profile.merchant.type
        }
      } else {
        return {}
      }
    },
    account (state) {
      if (state.profile) {
        return state.profile.merchant.account
      } else {
        return ''
      }
    },
    userRole (state) {
      if (state?.profile?.user?.is_employee) {
        return state.profile.user.employee_type
      } else if (state.profile) {
        return state.profile.merchant.account.role
      }
      return { key: '', value: '' }
    },
    userType (state) {
      if (state?.profile?.user?.is_employee) {
        return state.profile.user.employee_type
      } else if (state.profile) {
        return state.profile.merchant.type
      }
      return { key: '', value: '' }
    },
    hasMultipleAccounts (state) {
      if (state?.profile?.merchant) {
        return state.profile.merchant.has_multiple_accounts
      }
      return false
    },
    merchantId (state) {
      return state?.profile?.merchant?.id
    },
    accountId (state) {
      return state?.profile?.merchant?.account?.id
    },
    userInitials (state) {
      if (state.profile)
        return ProfileInstance.support.getInitials(
          state.profile.user.first_name,
          state.profile.user.last_name
        )
    },
    hasUserTwoFactorAuthentication (state) {
      return state.profile?.user_two_factor_authentication?.enabled ?? false
    },
    timelineActivation (state) {
      return state?.timelineActivation
    }
  },
  actions: {
    GET_AUTHORIZATION (context, params = {}) {
      return ProfileInstance.authorization
        .get(params, 'dash', process.env.VUE_APP_PROFILE_URL)
        .then(res => {
          if (window.newrelic) {
            window.newrelic
              .interaction()
              .setAttribute('id', res.user.id)
              .setAttribute(
                'username',
                `${res.user.first_name} ${res.user.last_name}`
              )
              .setAttribute('email', res.user.email)
              .setAttribute(
                'merchant_id',
                res.merchant ? res.merchant.name : null
              )
          }
          return res
        })
        .then(res => {
          if (res.merchant) {
            context.commit('SET_MERCHANT_NAME', res.merchant.name)
            context.commit('SET_PROFILE', res)
            context.commit('SET_STATUS_ACCOUNT', res.merchant.account.status)
            context.commit('SET_TEST_ACCOUNTS', { items: [], pagination: [] })
            context.commit(
              'SET_BUSINESS_MODEL',
              res.merchant.account.business_model
            )
            context.commit('IDENTIFY_APPCUES')
            context.commit('SET_ACCOUNT_VENDOR')
            if (!isSellerUserRole(res.merchant.account.role.key))
              context.dispatch('GET_ONBOARDING')
            if (
              !res.user.is_employee &&
              context.getters.componentIsEnabled('global', 'nps')
            )
              context.dispatch('GET_NPS')
          }
          return Promise.resolve(res)
        })
        .catch(err => {
          context.commit('toggleGlobalLoading', false)
          return catchError(err, context)
        })
    },
    DELETE_AUTHORIZATION (context, logout = false) {
      return IDCoreInstance.auth.connect
        .revocationToken()
        .catch(err => catchError(err, context))
        .finally(() => {
          ProfileInstance.authorization.deleteCookies('dash', logout)
          window.location.assign(process.env.VUE_APP_DEFAULT_REDIRECT)
          return Promise.resolve()
        })
    },
    GET_ME (context, params = {}) {
      return ProfileInstance.me
        .get()
        .then(res => {
          context.commit('SET_ME', res.data)
          context.commit('toggleGlobalLoading', false)
          Promise.resolve(res.data)
        })
        .catch(err => {
          context.commit('toggleGlobalLoading', false)
          return catchError(err, context)
        })
    },
    CLOSE_GREETING (context) {
      context.commit('SET_SHOW_GREETING', false)
      context.dispatch('UPDATE_ONBOARDING')
    },
    CLOSE_PIX_ONBOARDING (context) {
      context.commit('SET_PIX_ONBOARDING', false)
      context.dispatch('UPDATE_ONBOARDING')
    },
    CLOSE_MODAL_HEADER (context) {
      context.commit('SET_SHOW_MODAL_HEADER', false)
      context.dispatch('UPDATE_ONBOARDING')
    },
    CLOSE_TOOLTIP_FILTER_ONBOARDING (context, type) {
      context.commit('SET_TOOLTIP_FILTER_ONBOARDING', { type, value: true })
      context.dispatch('UPDATE_ONBOARDING')
    },
    TOGGLE_ONBOARDING (context, showOnboarding) {
      context.commit('SET_SHOW_ONBOARDING', showOnboarding)
    },
    TOGGLE_SHOW_ONBOARDING_HELP (context, showOnboardingHelp) {
      context.commit('SET_SHOW_ONBOARDING_HELP', showOnboardingHelp)
    },
    UPDATE_ONBOARDING_STEP (context, step) {
      if (step === 4) {
        step = 0
      }
      context.commit('SET_ONBOARDING_STEP', step)
      context.dispatch('UPDATE_ONBOARDING')
    },
    CLOSE_SUMMARY (context, summaryState) {
      context.commit('SET_ONBOARDING_SUMMARY', summaryState)
      context.dispatch('UPDATE_ONBOARDING')
    },
    GET_ONBOARDING (context) {
      const envAcronym = DashInstance.envAcronym
      let cookieDash = Cookies.get(`${envAcronym}mp_dash`)
      cookieDash = cookieDash ? JSON.parse(atob(cookieDash)) : {}

      if (
        cookieDash.onboarding &&
        Object.entries(cookieDash.onboarding).length
      ) {
        getCookieOnboardingDash(context, cookieDash)
      } else {
        return fetchOnboardingDash(context)
      }
      context.commit('toggleGlobalLoading', false)
      return Promise.resolve(cookieDash.onboarding)
    },
    UPDATE_ONBOARDING (context) {
      updateCookieDash('onboarding')
      const onboarding = context.state.onboarding
      return ProfileInstance.onboarding.update('dash', onboarding)
    },
    DISABLED_TIMELINE_ACTIVATION ({ commit, state, getters }) {
      commit('SET_TIMELINE_STEP_ACTIVATION', STEPS_TIMELINE.DISABLED)
      setCookieTimelineWithAccountId(
        getters.account.id,
        state.timelineActivation
      )
    },
    UPDATE_TIMELINE_STEP_ACTIVATION ({ commit, state, getters }, step) {
      commit('SET_TIMELINE_STEP_ACTIVATION', step)
    },
    GET_TIMELINE_ACTIVATION ({ commit, getters }) {
      const coockieTimeline = getCookieTimelineByAccountId(getters.account.id)

      if (coockieTimeline?.step) {
        commit('SET_TIMELINE_STEP_ACTIVATION', coockieTimeline?.step)
      }
    }
  }
}

export default profile
